<template>
	<div class="aboutUs">
       <div class="aboutImgs">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/01-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/02-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/03-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/04-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/05-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/06-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/07-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/08-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/09-20210825.jpg">
            <img src="https://sc2.hexiaoxiang.com/web_static/m/about/10-20210825.jpg">
        </div>
		<!-- <h1>公司简介</h1>
		<p class="brief" v-for="(item,index) in data.brief" :key="index">{{item}} </p>
		<h2>企业使命</h2>
        <p class="msg">
           {{data.task}}
        </p>
        <h2>创始人简介 </h2>
        <p class="msg-brief">
           {{data.companyBrief}}
        </p>
        <h2>大事记</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.notes" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
           <h2>企业荣誉</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.glory" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
      <h2>融资情况 </h2>
        <p class="msg">
          <ul>
              <li v-for="(item,index) in data.money" :key="index">{{item}}</li>
         </ul>
        </p>
         <h2 v-if="data.invest.length>0">投资方介绍</h2>
        <p class="msg">
            <ul>
                <li v-for="(item,index) in data.invest" :key="index">{{item.name}}</li>
            </ul>

        </p> -->
	</div>
</template>
<script>

export default {
	name: 'aboutUs',
	data () {
		return {
			data:{}
		}
	},
    created(){
        this.getData()
    },
    methods:{
        getData(){
        this.axios.get('https://sc2.hexiaoxiang.com/web_static/new_hx_web/aboutUs.json?v=20210616').then(res=>{
           this.data=res.data
         })
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutUs{
    text-align: left;
    // padding: .853rem .493rem;
    .aboutImgs{
      img{
          width: 100%;
      }
    }

    h1{
        font-size: .533rem;
        color:#444144;
        font-weight: bold;
    }
    .brief{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        text-indent:2em;
        margin: .36rem 0 .4rem;
    }
    h2{
        color: #FF7041;
        font-size: .427rem;
        font-weight: bold;
    }
    .msg{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        margin: .227rem 0 .507rem;

    }
    .msg li span{
        font-weight: bold;
        margin-right: .267rem;
    }
    .msg p{
            text-indent: .267rem;
    }
     .msg-brief{
       font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        text-indent:2em;
        margin: .227rem 0 .507rem;
    }
}
</style>
